// JsFromRoutes CacheKey b3572bbdabaaec1f69421c0292f10c8d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  evaluate: /* #__PURE__ */ definePathHelper(
    'get',
    '/api/experiments/:experiment_name/evaluate',
  ),
  index: /* #__PURE__ */ definePathHelper('get', '/api/experiments(/:prefix)'),
  update: /* #__PURE__ */ definePathHelper(
    'put',
    '/api/experiments/:experiment_name/:variant',
  ),
}
